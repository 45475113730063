import anime, { stagger } from 'animejs';
import LocomotiveScroll from 'locomotive-scroll';

import GridDebug from './utils/GridDebug';
import FPS from './utils/FPS';

// new GridDebug(24,0);
// new FPS();

let scroll;

/* ------------------------------------ */
/* ------ Locomotive scroll init ------ */
/* ------------------------------------ */
const createScroll = (container) => {
    let elt = container.querySelector('[data-scroll-container]');

    scroll = new LocomotiveScroll({
        el: elt,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        gestureDirection: 'both'
    });

    return scroll;
};

// const isHome = () => { return  }


const page = () => {

    // if (isHome()) {
    //     ...
    // }
    // if (WU.isMobile() && WU.isXS()) {
    //     console.log('toto');
    // }
    

};


(function() {
    
    scroll = createScroll(document);

    page();

})();